import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from '../../firebase/setup'
import { AuthForm } from './AuthForm'
import { useEffect } from 'react'
import {
  useNavigate
  // useSearchParams
} from 'react-router-dom'
import { Loading } from '../../components'
import { useFormStore } from '../../stores/form.store'

export const Home = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, loading] = useAuthState(firebaseAuth)

  const setOrigen = useFormStore(state => state.setOrigen)

  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const idOrigen = params.get('IdOrigen') ?? ''

    setOrigen(idOrigen)

    if (user && !loading) {
      navigate({
        pathname: '/form'
      })
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="bg-light-red h-[92vh] md:h-[100vh]  flex flex-col gap-6 md:gap-10 justify-center items-center py-4">

      <div className="flex flex-col items-center gap-2 md:gap-4">

        <h1 className="text-white text-2xl md:text-5xl lg:text-6xl font-bold">
          Pre-aprobación en minutos
        </h1>

        <span className="font-bold text-xl">
          Préstamo para Vivienda
        </span>

        <p className="text-md md:text-2xl lg:text-3xl text-white text-center">
          Con esta solución podrás tener una respuesta inmediata
          sobre la financiación de tu futura vivienda.
        </p>
      </div>

      <AuthForm />

    </div>
  )
}
