import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import CurrencyInput from 'react-currency-input-field'

import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { Modal } from '../../../components'
import { SweetAlert, calcularEndeudamiento, currencyFormat } from '../../../utils'
import { type Egreso } from '../../../types'
import { useFormStore } from '../../../stores/form.store'
import { useWizard } from 'react-use-wizard'

interface FormData { Egresos: Egreso[] }

export const Egresos = (): JSX.Element => {
  const [showModalEgresos, setShowModalEgresos] = useState(false)
  const [currentEgreso, setCurrentEgreso] = useState({
    Cupo: 0,
    Saldo: 0,
    Descripcion: ''
  })

  const addEgresos = useFormStore(state => state.addEgresos)
  const currentSolicitante = useFormStore(state => state.getCurrentSolicitante())

  const { handleStep } = useWizard()

  const {
    control,
    handleSubmit,
    reset
  } = useForm<FormData>({
    defaultValues: {
      Egresos: currentSolicitante?.DataFinanciera.Egresos
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Egresos',
    rules: {
      maxLength: 8
    }
  })

  const onSubmit: SubmitHandler<FormData> = async ({ Egresos }): Promise<void> => {
    if (Egresos.length > 0) {
      addEgresos(Egresos)
    }
  }

  const handleAddEgreso = (tipo: string): void => {
    setCurrentEgreso(state => ({
      ...state,
      Descripcion: tipo
    }))
    setShowModalEgresos(true)
  }

  const handleToggleModalOtp = (): void => { setShowModalEgresos(prev => !prev) }

  const checkIsCuotaFija = (): boolean => {
    const { Descripcion } = currentEgreso
    return Descripcion !== 'TARJETAS DE CREDITO' && Descripcion !== 'CREDITO ROTATIVO'
  }

  const tarjetasCredito = fields.filter(field => field.Descripcion === 'TARJETAS DE CREDITO')
  const creditosRot = fields.filter(field => field.Descripcion === 'CREDITO ROTATIVO')
  const creditosCuotaFija = fields.filter(field => {
    if (
      field.Descripcion === 'TARJETAS DE CREDITO' ||
      field.Descripcion === 'CREDITO ROTATIVO'
    ) return false
    return true
  })

  handleStep(async () => {
    await new Promise((resolve) => {
      void handleSubmit(
        async (data) => {
          await onSubmit(data)
          resolve(data)
        }
      )()
    })
  })

  useEffect(() => {
    if (currentSolicitante) {
      reset({
        Egresos: currentSolicitante?.DataFinanciera.Egresos
      })
    }
  }, [currentSolicitante])

  return (

    <>
      <div className='min-h-[80%] py-6 md:py-8 px-10'>

        <h1 className='text-md font-bold text-center col-span-3 mb-1'>
          DEUDA EN PESOS COLOMBIANOS, SOLO APLICA LAS
          DEUDAS QUE TENGAS EN COLOMBIA, NO EN USA.
        </h1>

        <h1 className=' text-gray-600 text-md text-center col-span-3'>
          Indica tu nivel de endeudamiento actual y menciona tus productos financieros para un análisis de crédito preciso.
        </h1>

        <h2 className='mt-1 text-center text-xl font-bold'>
          TOTAL ENDEUDAMIENTO ACTUAL: <br />
          <span className='text-light-red'>
            $ {
              currencyFormat(
                fields.reduce((total, field) => {
                  const newTotal = total += field.Endeudamiento
                  return newTotal
                }, 0)
              )
            }
          </span>
        </h2>

        <div className="w-full flex flex-col md:flex-row gap-4 mt-4">
          <div className='card w-full bg-white shadow-2xl p-2 md:p-4'>

            <div className="flex justify-between items-center">
              <span className='font-bold'>
              Tarjetas de Crédito
              </span>

              <div className="tooltip" data-tip="Agregar">
                <button
                  onClick={() => { handleAddEgreso('TARJETAS DE CREDITO') }}
                  className='btn btn-sm btn-error rounded-full text-white font-bold'>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>

            <ul>
              {

                tarjetasCredito.length === 0
                  ? (
                  <h1 className='text-center my-4 py-2 bg-base-300 dark:bg-white rounded-box font-bold'>
                    No hay tarjetas agregadas
                  </h1>
                    )
                  : (
                      tarjetasCredito.map((field, index) => {
                        return (
                      <li key={field.id} className='mt-2'>
                        <div className="grid py-2 px-3 bg-base-300 dark:bg-white rounded-box place-items-start">
                          <div className='flex justify-between items-center w-full'>
                            <strong>Tarjeta # {`${index + 1}`}</strong>

                            {/* <button className='btn btn-sm btn-outline text-light-red'>
                              Editar
                              <FontAwesomeIcon icon={faEdit} />
                            </button> */}
                          </div>

                          <div className='w-full mt-2'>
                            <span>Cupo $ {currencyFormat(field.Cupo)}</span>
                            <div className="divider m-0"></div>
                            <span>Saldo $ {currencyFormat(field.Saldo)}</span>
                          </div>

                          <div className="w-full text-center mt-2">
                            <button
                              onClick={() => {
                                const indexField = fields.map(e => e.id).indexOf(field.id)
                                remove(indexField)
                              }}
                              className='btn btn-sm btn-error text-white text-center'>
                                Eliminar
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>

                        </div>
                      </li>
                        )
                      })
                    )
              }
            </ul>

          </div>

          <div className='card w-full bg-white shadow-2xl p-4'>

            <div className="flex justify-between items-center">
              <span className='font-bold'>
                Créditos Rotativos
              </span>

              <div className="tooltip" data-tip="Agregar">
                <button
                  onClick={() => { handleAddEgreso('CREDITO ROTATIVO') }}
                  className='btn btn-sm btn-error rounded-full text-white font-bold'>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>

            <ul>
              {

                creditosRot.length === 0
                  ? (
                  <h1 className='text-center my-4 py-2 bg-base-300 dark:bg-white rounded-box font-bold'>
                    No hay créditos
                  </h1>
                    )
                  : (
                      creditosRot.map((field, index) => {
                        return (
                      <li key={field.id} className='mt-2'>
                        <div className="grid py-2 px-3 bg-base-300 dark:bg-white rounded-box place-items-start">
                          <div className='flex justify-between items-center w-full'>
                            <strong>Credito # {`${index + 1}`}</strong>

                            {/* <button className='btn btn-sm btn-outline text-light-red'>
                              Editar
                              <FontAwesomeIcon icon={faEdit} />
                            </button> */}
                          </div>

                          <div className='w-full mt-2'>
                            <span>Cupo $ {currencyFormat(field.Cupo)}</span>
                            <div className="divider m-0"></div>
                            <span>Saldo $ {currencyFormat(field.Saldo)}</span>
                          </div>

                          <div className="w-full text-center mt-2">
                            <button
                              onClick={() => {
                                const indexField = fields.map(e => e.id).indexOf(field.id)
                                remove(indexField)
                              }}
                              className='btn btn-sm btn-error text-white text-center'>
                                Eliminar
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>

                        </div>
                      </li>
                        )
                      })
                    )
              }
            </ul>

          </div>

          <div className='card w-full bg-white shadow-2xl p-4'>

            <div className="flex justify-between items-center">
              <span className='font-bold'>
                Créditos Cuota Fija
              </span>

              <div className="tooltip" data-tip="Agregar">
                <button
                  onClick={() => { handleAddEgreso('Hipotecario') }}
                  className='btn btn-sm btn-error rounded-full text-white font-bold'>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>

            <ul>
              {

                creditosCuotaFija.length === 0
                  ? (
                  <h1 className='text-center my-4 py-2 bg-base-300 dark:bg-white rounded-box font-bold'>
                    No hay créditos
                  </h1>
                    )
                  : (
                      creditosCuotaFija.map((field, index) => {
                        return (
                          <li key={field.id} className='mt-2'>
                            <div className="grid py-2 px-3 bg-base-300 dark:bg-white rounded-box place-items-start">
                              <div className='flex justify-between items-center w-full'>
                                <strong>Credito Fijo # {`${index + 1}`}</strong>

                                {/* <button className='btn btn-sm btn-outline text-light-red'>
                                  Editar
                                  <FontAwesomeIcon icon={faEdit} />
                                </button> */}
                              </div>

                              <div className='w-full mt-2'>
                                <span>{field.Descripcion}</span>
                                <div className="divider m-0"></div>
                                <span>Cuota $ {currencyFormat(field.Saldo)}</span>
                              </div>

                              <div className="w-full text-center mt-2">
                                <button
                                  onClick={() => {
                                    const indexField = fields.map(e => e.id).indexOf(field.id)
                                    remove(indexField)
                                  }}
                                  className='btn btn-sm btn-error text-white text-center'>
                                    Eliminar
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>

                            </div>
                          </li>
                        )
                      })
                    )
              }
            </ul>

          </div>
        </div>

      </div>

      <Modal
        onClose={() => { handleToggleModalOtp() }}
        open={showModalEgresos}
        disableClickOutside
      >

        <h2 className="font-bold text-center">
          <span className='text-light-red text-xl'>
            {`${checkIsCuotaFija() ? 'CUOTA FIJA' : currentEgreso.Descripcion}`}
          </span>
          <br />
          <span className='text-center text-gray-500'>
            Proporciona a continuación los detalles sobre tus egreso.
          </span>
        </h2>

        <div className="py-2 w-full">

          <div className="grid grid-cols-2 my-4 gap-4 place-content-center">
            <div className={cn('form-control bg-white', {
              'hidden': checkIsCuotaFija()
            })}>
              <span className='label-text font-bold text-sm mb-2'>
                Cupo *
              </span>

              <CurrencyInput
                value={currentEgreso.Cupo}
                placeholder='$ 8,500,000.00'
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix='$ '
                className='input input-bordered w-full bg-white'
                onValueChange={(value) => {
                  const parseValue = value ? +value : 0
                  setCurrentEgreso(state => ({
                    ...state,
                    Cupo: parseValue
                  }))
                }}
              />
            </div>

            <div className={cn('form-control bg-white', {
              'hidden': checkIsCuotaFija()
            })}>
              <span className='label-text font-bold text-sm mb-2'>
                Saldo *
              </span>

              <CurrencyInput
                value={currentEgreso.Saldo}
                placeholder='$ 2,500,000.00'
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix='$ '
                className='input input-bordered w-full bg-white'
                onValueChange={(value) => {
                  const parseValue = value ? +value : 0
                  setCurrentEgreso(state => ({
                    ...state,
                    Saldo: parseValue
                  }))
                }}
              />
            </div>

            <div className={cn('form-control bg-white', {
              'hidden': !checkIsCuotaFija()
            })}>
              <span className='label-text font-bold text-sm mb-2'>
                Tipo *
              </span>

              <select
                value={currentEgreso.Descripcion}
                className="select select-bordered w-full bg-white"
                onChange={({ target: { value } }) => {
                  setCurrentEgreso(state => ({
                    ...state,
                    Descripcion: value
                  }))
                }}
              >
                <option>Hipotecario</option>
                <option>Consumo</option>

              </select>
            </div>

            <div className={cn('form-control', {
              'hidden': !checkIsCuotaFija()
            })}>
              <span className='label-text font-bold text-sm mb-2'>
                Cuota Mensual *
              </span>

              <CurrencyInput
                value={currentEgreso.Saldo}
                placeholder='$ 2,500,000.00'
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix='$ '
                className='input input-bordered w-full bg-white'
                onValueChange={(value) => {
                  const parseValue = value ? +value : 0
                  setCurrentEgreso(state => ({
                    ...state,
                    Saldo: parseValue
                  }))
                }}
              />
            </div>

          </div>

          <div className='flex justify-between'>
            <button className='btn dark:bg-white' onClick={() => {
              handleToggleModalOtp()
            }}>
              Cancelar
            </button>

            <button
              onClick={() => {
                handleToggleModalOtp()

                const { Cupo, Descripcion, Saldo } = currentEgreso

                const isCuotaFija = checkIsCuotaFija()

                if (isCuotaFija && Saldo <= 0) {
                  void SweetAlert({
                    message: 'Es necesario agregar una cuota mensual',
                    icon: 'warning'
                  })
                  return
                }

                if (!isCuotaFija && (Saldo <= 0) && (Cupo <= 0)) {
                  void SweetAlert({
                    message: 'Saldo y Cupo son obligatorios',
                    icon: 'warning'
                  })
                  return
                }

                const endeudamiento = isCuotaFija
                  ? Saldo
                  : calcularEndeudamiento({
                    cupo: Cupo,
                    saldo: Saldo,
                    tipo: Descripcion
                  })

                append({
                  ...currentEgreso,
                  Endeudamiento: endeudamiento
                })

                setCurrentEgreso({
                  Cupo: 0,
                  Saldo: 0,
                  Descripcion: ''
                })
              }}
              className='btn btn-primary'>
              Agregar
            </button>
          </div>
        </div>

      </Modal>

    </>
  )
}
