import { type StateCreator, create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { v4 as uuidV4 } from 'uuid'
import dayjs from 'dayjs'
import { type AnalisisCredito, type Solicitante } from '../types'

interface FormState {
  solicitantes: Record<string, Solicitante>
  targetId: string
  totalSolicitantes: number
  analisisCredito: AnalisisCredito
  tipoInmueble: string
  idOrigen: string
  setSolicitante: (tipo: Solicitante['TipoSolicitante'], phone: string) => string
  setTarget: (id: string) => void
  getCurrentSolicitante: () => Solicitante
  updateSolicitante: (data: Partial< Omit<Solicitante, 'DataFinanciera'> >) => void
  removeSolicitante: (id: string) => void
  addIngresos: (data: Solicitante['DataFinanciera']['Ingresos']) => void
  addEgresos: (data: Solicitante['DataFinanciera']['Egresos']) => void
  setAnalisisCredito: (data: AnalisisCredito) => void
  setOrigen: (id: string) => void
  reset: () => void
  setTipoInmueble: (tipo: string) => void
}

const storeApi: StateCreator<
FormState, [
  ['zustand/persist', unknown],
  ['zustand/devtools', never],
  ['zustand/immer', never]
], [] > = (set, get) => ({
  solicitantes: {},
  targetId: '',
  totalSolicitantes: 0,
  tipoInmueble: '',
  analisisCredito: {
    CiudadInmueble: '',
    Producto: 'RESIDENTE_EXTERIOR',
    ValorInmueble: 0
  },
  idOrigen: '',

  setSolicitante: (tipo, phone = '+57') => {
    const id = uuidV4()
    const newSolicitante: Solicitante = {
      Nombres: '',
      TipoIdentificacion: 'CEDULA',
      Identificacion: '',
      FechaNacimiento: dayjs().subtract(18, 'years').subtract(2, 'days').format('YYYY-MM-DD'),
      Celular: phone,
      Correo: '',
      CiudadResidencia: 'Bogotá D.C.',
      TipoSolicitante: tipo,
      ContinuidadLaboral: false,
      FechaInicioTrabajoActual: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      FechaInicioTrabajoAnterior: '',
      FechaFinTrabajoAnterior: '',
      Actividad: 10,
      Residente: false,
      ComercioLocal: false,
      DataFinanciera: {
        Egresos: [],
        Ingresos: []
      }
    }
    set(state => {
      state.solicitantes[id] = newSolicitante
      state.totalSolicitantes += 1
    }, false, 'setSolicitantes')

    return id
  },
  setTarget: (id) => { set({ targetId: id }) },
  getCurrentSolicitante: () => get().solicitantes[get().targetId],
  updateSolicitante: (data) => {
    const target = get().targetId
    const currentInfo = get().solicitantes[target]

    set(state => {
      state.solicitantes[target] = {
        ...currentInfo,
        ...data
      }
    }, false, 'updateSolicitante')
  },
  removeSolicitante: (id) => {
    const newSolicitantes = { ...get().solicitantes }
    delete newSolicitantes[id]

    set(state => {
      state.solicitantes = newSolicitantes
    }, false, 'removeSolicitante')
  },
  addIngresos: (ingresos) => {
    const target = get().targetId
    set(state => {
      state.solicitantes[target].DataFinanciera.Ingresos = ingresos
    }, false, 'addIngresos')
  },
  addEgresos: (egreso) => {
    const target = get().targetId
    set(state => {
      state.solicitantes[target].DataFinanciera.Egresos = egreso
    }, false, 'addEgresos')
  },
  setAnalisisCredito: (data) => {
    set(state => {
      state.analisisCredito = data
    })
  },
  setTipoInmueble: (tipo) => {
    set(state => {
      state.tipoInmueble = tipo
    })
  },
  reset: () => {
    set(state => {
      state.analisisCredito = {
        CiudadInmueble: '',
        Producto: '',
        ValorInmueble: 0
      }
      state.solicitantes = {}
      state.targetId = ''
      state.totalSolicitantes = 0
      state.tipoInmueble = ''
    })
  },
  setOrigen: (id) => {
    set(state => {
      state.idOrigen = id
    })
  }
})

export const useFormStore = create<FormState>()(
  persist(
    devtools(
      immer(storeApi)
    )
    , { name: 'form-store' })
)
