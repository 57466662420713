import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import CurrencyInput from 'react-currency-input-field'

import { AutoComplete } from '../../../components'
import { meiliSearch } from '../../../utils'
import { schemaPropiedad } from '../../../validation/steps'
import { type AnalisisCredito } from '../../../types'
import { useFormStore } from '../../../stores/form.store'
import { useWizard } from 'react-use-wizard'

interface MeiliCiudad {
  name: string
  tope_vis: number
  tope_vip: number
}

type FormData = AnalisisCredito

export const Propiedad = (): JSX.Element => {
  const setAnalisisCredito = useFormStore(state => state.setAnalisisCredito)
  const analisisCredito = useFormStore(state => state.analisisCredito)

  const [suggestions, setSuggestions] = useState<MeiliCiudad[]>([])

  const { handleStep } = useWizard()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm<FormData>({
    resolver: joiResolver(schemaPropiedad),
    defaultValues: analisisCredito
  })

  const handleSearchCiudades = async (searchTerm: string): Promise<void> => {
    if (!searchTerm) return

    const results = await meiliSearch<MeiliCiudad[]>({
      index: 'scotia_ciudades',
      attributesToRetrieve: 'name,tope_vis,tope_vip',
      attributesToSearchOn: 'name',
      filter: 'target=1',
      searchTerm
    })

    setSuggestions(results)
  }

  const onSubmit: SubmitHandler<FormData> = (data): void => {
    setAnalisisCredito(data)
  }

  handleStep(async () => {
    await new Promise((resolve) => {
      void handleSubmit(
        async (data) => {
          await onSubmit(data)
          resolve(data)
        }
      )()
    })
  })

  return (
    <div className='grid grid-cols-1 justify-items-center gap-4 py-8 px-10'>
      <div className="form-control w-full md:w-1/2">
        <span className='label-text font-bold text-md mb-2'>¿Ciudad del inmueble? *</span>

        <AutoComplete
          defaultValue={getValues('CiudadInmueble')}
          items={suggestions.map(e => e.name)}
          onInput={handleSearchCiudades}
          onSelect={(city) => {
            setValue('CiudadInmueble', city)
          }}
        />

        {
          errors.CiudadInmueble &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.CiudadInmueble.message}
            </span>
          </div>
        }
      </div>

      <div className="form-control w-full md:w-1/2">
        <span className='label-text font-bold text-md mb-2'>Valor del Inmueble (En Dólares) *</span>

        <CurrencyInput
          defaultValue={getValues('ValorInmueble')}
          placeholder='$ 100,000.00'
          decimalsLimit={2}
          allowNegativeValue={false}
          prefix='$ '
          className='input input-bordered w-full bg-white'
          onValueChange={(value) => {
            const input = value ? +value : 0
            setValue('ValorInmueble', input)
          }}
        />

        {
          errors.ValorInmueble &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.ValorInmueble.message}
            </span>
          </div>
        }

      </div>

      <div className="form-control w-full md:w-1/2" >
        <span className='label-text font-bold text-md mb-2'>Deseas tu crédito para *</span>

        <select
          {...register('Producto')}
          defaultValue="RESIDENTE_EXTERIOR"
          className='select select-bordered bg-white'
        >
          <option value="RESIDENTE_EXTERIOR">Adquisición de vivienda siendo residente en el extranjero</option>
        </select>

        {
          errors.Producto &&
          <div className="label">
            <span className="label-text-alt text-error">
                {errors.Producto.message}
            </span>
          </div>
        }
      </div>

    </div>
  )
}
