import Swal, { type SweetAlertOptions, type SweetAlertResult } from 'sweetalert2'
import { pmt } from 'financial'
import axios from 'axios'

interface Alert {
  message?: string
  icon?: SweetAlertOptions['icon']
}

export const SweetAlert = async ({ message = 'Al parecer algo salió mal :(', icon = 'error' }: Alert): Promise<SweetAlertResult> => await Swal.fire({
  title: 'INFO',
  text: message,
  icon
})

interface DataEgreso {
  cupo: number
  saldo: number
  tipo: string
}

export const calcularEndeudamiento = ({ cupo, saldo, tipo }: DataEgreso): number => {
  let plazo = 0
  const tasaInteres = 0.03

  switch (tipo) {
    case 'TARJETAS DE CREDITO':
      plazo = 24
      break
    case 'CREDITO ROTATIVO':
      plazo = 36
      break
  }

  const cuotaSaldo = pmt(tasaInteres, plazo, -saldo)

  const cuotaContigente = pmt(tasaInteres, plazo, (-cupo + saldo)) * 0.2

  return Math.round(cuotaSaldo + cuotaContigente)
}

export const currencyFormat = (value: number): string => new Intl.NumberFormat('en-US').format(value)

interface MeiliSearch {
  index: string
  searchTerm: string
  attributesToRetrieve?: string
  attributesToSearchOn?: string
  filter?: string
  limit?: number
}

export const meiliSearch = async <T>({
  index,
  filter,
  searchTerm,
  attributesToSearchOn,
  attributesToRetrieve,
  limit = 6
}: MeiliSearch): Promise<T> => {
  const url = `${import.meta.env.VITE_MEILI_HOST}/indexes/${index}/search`

  const params = {
    q: searchTerm,
    filter,
    attributesToRetrieve,
    attributesToSearchOn,
    limit
  }

  const { data } = await axios.get(url, {
    params,
    headers: {
      'Authorization': `Bearer ${import.meta.env.VITE_MEILI_KEY}`
    }
  })

  return data.hits
}

interface CurrencyResponse {
  date: string
  usd: Record<string, number>
}

export const getCurrencyUsdInCop = async (): Promise<number> => {
  const { data } = await axios.get<CurrencyResponse>(
    'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json'
  )
  return data.usd.cop
}
