import { useState } from 'react'
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { Modal } from '../../components/Modal'
import { OtpInput } from '../../components/OtpInput'
import { useLogin } from '../../hooks/useLogin'

export const AuthForm = (): JSX.Element => {
  const [acceptPolicies, setAcceptPolicies] = useState(true)
  const [showModalOtp, setShowModalOtp] = useState(false)

  const {
    phone,
    otp,
    timeWaitingToNewSMS,
    sendingOtp,

    setPhone,
    setOtp,
    handleSubmitSms,
    handleValidateOtp,
    handleResetOtp
  } = useLogin()

  const handleToggleModalOtp = (): void => { setShowModalOtp(prev => !prev) }

  return (
    <>
        <div className="bg-white w-[90%] md:w-[45%] lg:w-[30%] rounded-2xl p-6 md:p-8 flex flex-col items-center gap-3 md:gap-4">
            <h2 className="text-2xl md:text-3xl font-bold">Empecemos!</h2>
            <p className="text-xl">Completa los siguientes datos</p>

            <PhoneInput
                defaultCountry='US'
                countries={['CO', 'US', 'HN', 'ES']}
                placeholder="(212) 555-1212"
                value={phone}
                onChange={setPhone}
                className='input input-bordered input-primary bg-white w-full max-w-xs'
            />

            <div id='recaptcha-container'></div>

            <small className='flex items-center'>
                <input
                    id="acceptConditions"
                    type="checkbox"
                    checked={acceptPolicies}
                    onChange={() => { setAcceptPolicies(!acceptPolicies) }}
                    className='checkbox-primary checkbox-md mr-2 md:mr-2'
                />

                <a
                    className='underline text-center'
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer">
                    He leído y acepto los Términos <br /> y Condiciones
                </a>
            </small>

            <button
                disabled={!acceptPolicies}
                onClick={
                  async () => {
                    const isSubmit = await handleSubmitSms()
                    isSubmit && handleToggleModalOtp()
                  }
                }
                className="btn btn-md text-white bg-light-red border-light-red font-bold mt-3 text-lg">
                Continuar
            </button>

        </div>

        <Modal
          open={showModalOtp}
          onClose={handleToggleModalOtp}
          disableClickOutside
        >
          <h2 className="font-bold text-xl text-center">
            Introduce el Codigo <br />
            <small className='text-sm font-normal'>
              Codigo enviado a {`${phone !== undefined ? formatPhoneNumberIntl(phone) : ''}`}
            </small>
          </h2>

          <div className='py-4 bg-white'>

            <OtpInput
              value={otp}
              autoSubmit={setOtp}
              className='input input-primary input-bordered bg-white px-0 text-center w-[50px] '
              onChange={ setOtp }
            />

          </div>
          <div className="modal-action flex justify-around bg-white">

            <button
              className='btn btn-active dark:bg-white'
              onClick={handleToggleModalOtp}
            >Cerrar</button>

            {
              timeWaitingToNewSMS <= 0
                ? <button
                  onClick={async () => { await handleResetOtp() }}
                  className='btn btn-outline btn-primary dark:bg-white'>
                    Solicitar un Nuevo Codigo
                  </button>
                : <small className='font-bold text-center'>Solicitar un Nuevo Codigo <br /> (Dentro de {`${timeWaitingToNewSMS}s`}) </small>
            }

            <button
              disabled={(otp.length < 6) || sendingOtp }
              onClick={async () => { await handleValidateOtp() }}
              className="btn btn-primary dark:bg-white">
              Validar
            </button>
          </div>
        </Modal>
    </>
  )
}
